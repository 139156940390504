<template>
  <v-select
    :id="id"
    ref="base-select"
    v-model="model"
    :class="hideBorders ? 'hide-input-borders' : ''"
    :options="computedOptions"
    :filterable="localFilterEnabled"
    :clearable="clearable"
    :reduce="reduce"
    :label="label"
    :disabled="internalDisabled"
    append-to-body
    :calculate-position="withPopper"
    :multiple="multiple"
    @open="onOpen"
    @close="onClose"
    @search="handleSearch"
    @option:selecting="$emit('select', $event)"
    @option:selected="$emit('selected', $event)"
  >
    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
    <template v-slot:no-options="{ searchTerm, searching }">
      <template v-if="searching">
        Sin opciones para <em>{{ search }}</em>.
      </template>
      <template v-else>
        <b-overlay
          v-if="loading"
          variant="white"
          spinner-variant="primary"
          rounded="sm"
          class="base-select-overlay"
        />
        <li v-else>
          Sin opciones
        </li>
      </template>
    </template>
    <template #list-footer>
      <li
        v-show="hasNextPage"
        ref="load"
      >
        <b-overlay
          show
          variant="white"
          spinner-variant="primary"
          rounded="sm"
          class="base-select-overlay"
        />
      </li>
    </template>
  </v-select>
</template>

<script>
import { createPopper } from '@popperjs/core'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { RESOURCE_ACTION_ALL, RESOURCE_ACTION_VIEW } from '@/shared/constants/resourceActions'

const PAGE_SIZE = 15

export default {
  name: 'BaseSelect',
  props: {
    value: {
      type: [Object, String, Number, Array],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    resource: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    reduce: {
      type: Function,
      default: item => item,
    },
    label: {
      type: String,
      default: 'name',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    httpMethod: {
      type: String,
      default: 'post',
    },
    httpBody: {
      type: Object,
      default: null,
    },
    httpQueryParams: {
      type: Object,
      default: null,
    },
    hideBorders: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null,
      search: '',
      searchTimeout: null,
      option: null,
      internalOptions: [],
      pageSize: PAGE_SIZE,
      totalItems: null,
      loading: false,
      popper: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['canAccessResource']),
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
        this.$emit('change')
      },
    },
    computedOptions() {
      return this.resource ? this.internalOptions : this.options || []
    },
    hasNextPage() {
      return !this.options && (!this.totalItems || this.internalOptions.length < this.totalItems)
    },
    localFilterEnabled() {
      return !this.resource || this.httpMethod !== 'post'
    },
    internalDisabled() {
      if (!this.resource) {
        return this.disabled
      }

      return this.disabled || (!this.canAccessResource(this.resource, RESOURCE_ACTION_ALL) && !this.canAccessResource(this.resource, RESOURCE_ACTION_VIEW))
    },
  },
  mounted() {
    this.addClearButtonEvent()
    this.observer = new IntersectionObserver(this.infiniteScrolling)
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width
      this.popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })

      return () => this.popper.destroy()
    },
    addClearButtonEvent() {
      const clearButton = this.$refs['base-select'].$children[0].$el
      clearButton.addEventListener('click', () => {
        if (this.disabled) {
          return
        }

        this.$emit('input', null)
        this.$emit('selected', null)
        this.$emit('clear')
      },
      false)
    },
    async loadData(bodyParams = {}) {
      if (!this.resource) {
        return
      }

      this.loading = true
      try {
        const response = await this.fetchDataByMethod(bodyParams)
        this.internalOptions = response.data.data
        this.totalItems = response.data.meta?.total[1] || this.computedOptions.length
        this.pageSize += PAGE_SIZE
        if (this.popper) {
          await this.popper.update()
        }
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    fetchDataByMethod(bodyParams) {
      if (this.httpMethod === 'post') {
        return Vue.prototype.$http.post(
          `/${this.resource}/list`,
          {
            search: this.search,
            page: 1,
            per_page: this.pageSize,
            ...this.httpBody,
            ...bodyParams,
          },
        )
      }

      return Vue.prototype.$http.get(`/${this.resource}`)
    },
    handleSearch(search) {
      if (this.localFilterEnabled) {
        return
      }

      this.search = search
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }

      this.searchTimeout = setTimeout(() => {
        this.pageSize = PAGE_SIZE
        this.loadData()
      }, 500)
    },
    async onOpen() {
      await this.$nextTick()
      this.observer.observe(this.$refs.load)
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScrolling([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        await this.$nextTick()
        await this.loadData()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<style scoped>
.hide-input-borders >>> .vs__dropdown-toggle {
  border: none;
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

>>> .vs__search {
  padding: 0;
}

>>> .vs__actions {
  padding: 4px;
}
</style>
